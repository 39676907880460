/*--------------------------------

	Fichier JS Startup

*/
// Hauteur header en fonction du scroll
$(document).on("scroll",function(){
	if (window.matchMedia("(min-width:1024px)").matches) {
		if($(document).scrollTop()>1){ 
			$(".header-site").addClass("small");
		}
		else{
			$(".header-site").removeClass("small");
		}
	}
});

$(document).ready(function(){
	$( "form" ).submit(function( event ) {spLoaderOn();});	
	
	//Navigation
	$( ".secondLevel" ).parent().addClass("has-dropdown");
	$( ".thirdLevel" ).parent().addClass("has-dropdown");
	if (window.matchMedia("(max-width:767px)").matches) {
		$(".toggle-topbar a").on('click', function() { 
			$("#nested").toggleClass("expanded");
			$("body").toggleClass("open");
		});
		$('.has-dropdown > a:first-child').each(function(){
			$(this).click(function(e) {
				e.preventDefault();
				$(this).next(".dropdown").slideToggle('fast');
			});
		});
	}
	if($('.chapter-img img[src*="logo"], .chapter-img img[src*="Logo"]').length > 0 ){ 
		$('.chapter-img').remove();
	}
	
	if($('.chapter-img img').length > 0 && $('body:not(.ru-offres)')) {
		var chaptersrc = $('.chapter-img img').attr('src');
		$('.hero-section').addClass('hero-section-image').css('background-image', 'url('+ chaptersrc +')');
	}
		
	if($('img[src*="logo"], img[src*="Logo"]').length > 0 ){ 
		$('img[src*="logo"], img[src*="Logo"]').each(function(){
			$(this).closest('.fit-cover').addClass('logo-img');
		});
	}
	if($('.map').length > 0 ){
		$('.map').each(function(){
			$(this).wrap('<div class="medias carte"></div>');
		});
	}
	if($('.heading-label').html() === $('h1').html() ){
		$('.heading-label').html('ESA, les charpentiers de demain');
	}
	
	// Ajout de la class active pour le menu flottant
	$('.menu-float a').filter(function () {
			return this.href == location.href;
	}).parent().addClass('active').siblings().removeClass('active');
	$('.menu-float a').click(function () {
			$(this).parent().addClass('active').siblings().removeClass('active');
	});

	
	//scroll pour ancre nommée
	$('article a[href^="#"], .anchor-article').click(function () {
			var the_id = $(this).attr("href");
			$('html, body').animate({
					scrollTop: $(the_id).offset().top-100
			}, 'slow');
		 return false;
	});	
	
	// Ajout de classes tactile ou noTactile selon type d'ecran
	if( !( 'ontouchstart' in window ) &&
		!navigator.msMaxTouchPoints &&
		!navigator.userAgent.toLowerCase().match( /windows phone os 7/i ) ) {
			$("body").addClass("noTactile");
	}else{
		$("body").addClass("tactile");
		$( '#nested ul.firstLevel>li:has(ul)' ).doubleTapToGo();
	}

	//Retour animé scroll haut de page
	//Check to see if the window is top if not then display button
	$(window).scroll(function(){
		if ($(this).scrollTop() > 100) {
			$('.scroll-top').fadeIn();
		} else {
			$('.scroll-top').fadeOut();
		}
	});
		
	// Animation au clic sur un lien avec ancre
	$('.js-scrollTo').on('click', function() { // Au clic sur un élément
		var page = $(this).attr('href'); // Page cible
		var speed = 400; // Durée de l'animation (en ms)
		$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
		return false;
	});

	//Tableaux responsive
	$( "table:not(.calendar)" ).wrap("<div class='table-responsive'></div>");
	
	//OBJECTFIT POLYFILL
	var userAgent,ieReg,ie;userAgent=window.navigator.userAgent,(ie=(ieReg=/msie|Trident.*rv[ :]*11\./gi).test(userAgent))&&($(".fit-cover").each(function(){var e=$(this),s=e.find("img").prop("src");s&&e.css("backgroundImage","url("+s+")").css("background-size","cover").css("background-repeat","no-repeat").addClass("custom-object-fit")}),$(".fit-contain").each(function(){var e=$(this),s=e.find("img").prop("src");s&&e.css("backgroundImage","url("+s+")").css("background-size","contain").css("background-repeat","no-repeat").addClass("custom-object-fit")}));

	if (document.documentMode || /Edge/.test(navigator.userAgent)) {
		$("html").addClass("no-support-grid");
	}
	
});

function spLoaderOn(){
	spLoaderOff();
	var div = $('<div id="spLoader" onclick="spLoaderOff()"></div>');
	$('body').append(div);
}

function spLoaderOff(tempo){
	if(tempo==true){
		setTimeout("spLoaderOff()",200) ;
		return;
	}
	$('#spLoader').remove();
}

// Double Tap to Go

;(function( $, window, document, undefined )
{
	$.fn.doubleTapToGo = function( params )
	{
		this.each( function()
		{
			var curItem = false;
			$( this ).on( 'click', function( e )
			{
				var item = $( this );
				if( item[ 0 ] != curItem[ 0 ] )
				{
					e.preventDefault();
					curItem = item;
				}
			});
			$( document ).on( 'click touchstart MSPointerDown', function( e )
			{
				var resetItem = true,
					parents	  = $( e.target ).parents();
				for( var i = 0; i < parents.length; i++ )
					if( parents[ i ] == curItem[ 0 ] )
						resetItem = false;
				if( resetItem )
					curItem = false;
			});
		});
		return this;
	};
})( jQuery, window, document );